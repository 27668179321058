import * as React from "react"
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  LinkBox,
  LinkOverlay,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { graphql, Link as GatsbyLink, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaVideo } from "react-icons/fa"
import gsap from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import "react-image-gallery/styles/css/image-gallery.css"

import { GatsbyImageGallery } from "../components/imageGalleries"
import Layout from "../components/layout"
import { IPortfolioHome } from "../components/portfolio"
import EmbededVideo from "../components/oEmbedVideo"
import { SEO } from "@avldev/gatsby-theme-core-ui/tools"
import { MediaContext } from "@avldev/gatsby-theme-core-ui/context"
import { TMediaContext } from "@avldev/gatsby-theme-core-ui/contextTypes"

interface TemplateProps extends PageProps {
  data: {
    home: IPortfolioHome
  }
}

interface UniformBoxProps extends BoxProps {
  children: React.ReactNode
}

function UniformBox(props: UniformBoxProps): JSX.Element {
  return <Box maxW="95vw" mx="auto" overflowY="auto" w="800px" {...props} />
}

function PortfolioHome({ data, location }: TemplateProps): JSX.Element {
  gsap.registerPlugin(ScrollToPlugin)

  const videoSectionRef = React.useRef<HTMLDivElement>()
  const { reduceMotion, width } = React.useContext<TMediaContext>(MediaContext)

  const { home } = data
  const { relatedClientStory: clientStory, floorPlan: plan } = home

  const hasMoreInfo = clientStory !== null || plan !== null
  const hasAllInfo = clientStory !== null && plan !== null

  const moreInfoTitleWidth = hasAllInfo
    ? { base: `100%` }
    : { base: `100%`, sm: `40%` }

  const scrollToVideo = () => {
    if (typeof window === `undefined`) return
    gsap.to(window, {
      duration: reduceMotion ? 0 : 0.5,
      scrollTo: {
        offsetY: window.innerHeight * 0.25,
        y: videoSectionRef.current,
      },
    })
  }

  const ogDesc = `View ${home.name}, a Sundog portfolio home in ${home.cityState}.`

  return (
    <Layout>
      <SEO
        meta={{
          description: ogDesc,
          image: home.images[0].gatsbyImageData.images.fallback.src,
          imageAlt: home.images[0].description,
        }}
        title={`${home.name} | Sundog Homes`}
        url={location.href}
      />
      <UniformBox
        bg="gray.600"
        color="white"
        mt={4}
        py={6}
        roundedTop="lg"
        w="1260px"
      >
        <Flex
          align={{ base: `start`, lg: `center` }}
          flexDir={{ base: `column`, lg: `row` }}
          justify="space-between"
          px={{ base: 4, lg: 6 }}
        >
          <Button
            as={GatsbyLink}
            colorScheme="white"
            leftIcon={<ArrowBackIcon />}
            mt={2}
            size="xl"
            to="/portfolio/"
            variant="link"
          >
            Back to Portfolio
          </Button>
          <Heading
            as="h1"
            borderBottom="4px solid"
            borderColor={{ base: `transparent`, lg: `red.300` }}
          >
            {home.name}
          </Heading>
        </Flex>
      </UniformBox>
      <UniformBox
        bg="gray.300"
        h={{ base: `60vh`, md: `80vh` }}
        pos="relative"
        roundedBottom="lg"
        w="1260px"
      >
        <GatsbyImageGallery
          images={home.images}
          itemWrapperProps={{
            h: { base: `calc(60vh - 78px)`, md: `calc(80vh - 78px)` },
          }}
          thumbnailProps={{ h: `60px` }}
        />
        {home.videos && (
          <Button
            aria-label="Scroll to video tour"
            bottom="85px"
            colorScheme="purple"
            left="5px"
            leftIcon={<Icon as={FaVideo} />}
            onClick={scrollToVideo}
            pos="absolute"
            size="md"
            variant="solid"
          >
            See Video Tour
          </Button>
        )}
      </UniformBox>
      <UniformBox mt={12}>
        <Box
          border="1px"
          borderColor="gray.200"
          mb={!hasMoreInfo ? 12 : 0}
          p={{ base: 2, sm: 6 }}
          rounded="lg"
        >
          <Table>
            <TableCaption fontFamily="body">Project Details</TableCaption>
            <Thead>
              <Tr>
                <Th fontFamily="body">Completed</Th>
                <Th colSpan={width < 430 ? 2 : 1} fontFamily="body">
                  Location
                </Th>
                {home.community && width >= 430 && (
                  <Th fontFamily="body">Community</Th>
                )}
              </Tr>
            </Thead>
            <Tbody bg="gray.50">
              <Tr>
                <Td>{home.projectFinish}</Td>
                <Td colSpan={width < 430 ? 2 : 1}>{home.cityState}</Td>
                {home.community && width >= 430 && <Td>{home.community}</Td>}
              </Tr>
            </Tbody>
            {home.community && width < 430 && (
              <>
                <Thead>
                  <Tr>
                    <Th colSpan={3} fontFamily="body">
                      Community
                    </Th>
                  </Tr>
                </Thead>
                <Tbody bg="gray.50">
                  <Tr>
                    <Td colSpan={3}>{home.community}</Td>
                  </Tr>
                </Tbody>
              </>
            )}
            {plan !== null && (
              <>
                <Thead>
                  <Tr>
                    <Th fontFamily="body">Beds</Th>
                    <Th fontFamily="body">Baths</Th>
                    <Th fontFamily="body">SqFt</Th>
                  </Tr>
                </Thead>
                <Tbody bg="gray.50">
                  <Tr>
                    <Td>{plan.beds}</Td>
                    <Td>{plan.baths}</Td>
                    <Td>{plan.sqft.toLocaleString(`en-US`)}</Td>
                  </Tr>
                </Tbody>
                <Thead>
                  <Tr>
                    <Th fontFamily="body">Plan</Th>
                    <Th colSpan={clientStory ? 1 : 2} fontFamily="body">
                      Series
                    </Th>
                    {clientStory && <Th fontFamily="body">Client</Th>}
                  </Tr>
                </Thead>
                <Tbody bg="gray.50">
                  <Tr>
                    <Td>{plan.name}</Td>
                    <Td colSpan={clientStory ? 1 : 2}>{plan.series.name}</Td>
                    {clientStory && <Td>{clientStory.clientSurname}</Td>}
                  </Tr>
                </Tbody>
              </>
            )}
            {plan === null && clientStory !== null && (
              <>
                <Thead>
                  <Tr>
                    <Th fontFamily="body">Plan</Th>
                    <Th colSpan={2} fontFamily="body">
                      Client
                    </Th>
                  </Tr>
                </Thead>
                <Tbody bg="gray.50">
                  <Tr>
                    <Td>Design/Build</Td>
                    <Td colSpan={2}>{clientStory.clientSurname}</Td>
                  </Tr>
                </Tbody>
              </>
            )}
          </Table>
        </Box>
      </UniformBox>
      {home.videos && (
        <UniformBox my={12}>
          <Flex flexDir="column" ref={videoSectionRef} w="100%">
            <Heading as="h2" color="gray.700" size="xl" mb={8}>
              Video Tour
            </Heading>
            <Box h="auto" w="100%">
              <EmbededVideo oEmbedUrl={home.videos[0]} />
            </Box>
          </Flex>
        </UniformBox>
      )}
      {hasMoreInfo && (
        <Flex
          align="start"
          bg="gray.50"
          border="1px solid"
          borderColor="gray.100"
          justify="space-evenly"
          maxWidth="95vw"
          mx="auto"
          my={12}
          px={{ sm: 2 }}
          rounded="lg"
          w="800px"
          wrap="wrap"
        >
          <Box alignSelf="center" px={4} w={moreInfoTitleWidth}>
            <Heading
              as={home.videos ? `h3` : `h2`}
              color="gray.700"
              size="lg"
              mb={2}
              mt={{ base: 6, md: hasAllInfo ? 6 : 0 }}
            >
              More About This Home
            </Heading>
          </Box>
          {clientStory && (
            <LinkBox
              align="center"
              as={Flex}
              flexDir="column"
              justify="center"
              py={4}
              pr={{ base: 4, sm: hasAllInfo ? 2 : 4 }}
              pl={4}
              rounded="lg"
              w={{ base: `100%`, sm: `50%` }}
            >
              <Image
                alt={clientStory.featuredImage.description}
                as={GatsbyImage}
                image={clientStory.featuredImage.gatsbyImageData}
                h={{ base: `250px`, sm: `200px`, md: `300px` }}
                rounded="xl"
                shadow="md"
                w="100%"
              />
              <LinkOverlay
                as={GatsbyLink}
                mt={2}
                to={`/client-stories/${clientStory.slug}`}
                _hover={{ color: `red.500`, textDecoration: `underline` }}
              >
                <Text fontSize="lg" fontWeight="semibold">
                  Read the owner's build experience
                </Text>
              </LinkOverlay>
            </LinkBox>
          )}
          {plan && (
            <LinkBox
              align="center"
              as={Flex}
              flexDir="column"
              justify="center"
              py={4}
              pl={{ base: 4, sm: hasAllInfo ? 2 : 4 }}
              pr={4}
              rounded="lg"
              w={{ base: `100%`, sm: `50%` }}
            >
              <Image
                alt={plan.elevations[0].description}
                as={GatsbyImage}
                image={plan.elevations[0].gatsbyImageData}
                h={{ base: `250px`, sm: `200px`, md: `300px` }}
                rounded="xl"
                shadow="md"
                w="100%"
              />
              <LinkOverlay
                as={GatsbyLink}
                mt={2}
                to={`/home-plans/${plan.slug}`}
                _hover={{ color: `red.500`, textDecoration: `underline` }}
              >
                <Text fontSize="lg" fontWeight="semibold">
                  Explore this home's floor plan
                </Text>
              </LinkOverlay>
            </LinkBox>
          )}
        </Flex>
      )}
    </Layout>
  )
}

export default PortfolioHome

export const query = graphql`
  query($slug: String!) {
    home: contentfulPortfolioHome(slug: { eq: $slug }) {
      ...PortfolioHome
      floorPlan {
        baths
        beds
        elevations {
          description
          gatsbyImageData(width: 400)
        }
        name
        series {
          name
        }
        slug
        sqft
      }
      relatedClientStory {
        clientSurname
        featuredImage {
          description
          gatsbyImageData(width: 400)
        }
        slug
      }
    }
  }
`
